import { Suspense } from 'react';
import { router } from './hooks/Routes';
import { Loading } from './components/loading/Loading';
import { RouterProvider } from 'react-router-dom';

// contextapi theme

function App() {
  return (
    <Suspense fallback={<Loading />}>
      <RouterProvider router={router} />
    </Suspense>
  );
}

export default App;
