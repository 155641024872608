import useQr from '../../hooks/useQr';
import { QrTemplate } from './QrTemplate';
import { BizContent } from './BizContent';

export const BizTemplate = () => {
  const { isQr, switchToQrPage } = useQr();

  return (
    <>
      {isQr ? (
        <QrTemplate switchToQrPage={switchToQrPage} />
      ) : (
        <BizContent switchToQrPage={switchToQrPage} />
      )}
    </>
  );
};
