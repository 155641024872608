import { current, createSlice } from '@reduxjs/toolkit';

// createSlice =  createReducer와 createAction

// interface UserInfoType {
//   id: number;
// }

const initialState = null;

const userSlice = createSlice({
  name: 'user', // reducer 이름
  initialState,
  reducers: {
    // 상태가 변하면 어떻게 실행될지 정하는 부분
    // login: (state, action) => {
    //   console.log(current(state), action);
    //   state.value = action.payload;
    // },

    setData(state, action) {
      return action.payload;
    },
  },
  selectors: {
    selectValue: (state) => {
      return state !== null && state;
    },
  },
});

export const { setData } = userSlice.actions;
export const { selectValue } = userSlice.selectors;
export default userSlice.reducer;
