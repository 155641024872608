import { useEffect, useState } from 'react';

const useQr = () => {
  const [isQr, setIsQr] = useState<boolean>(false);
  const switchToQrPage = () => {
    setIsQr(!isQr);
  };

  return { isQr, switchToQrPage };
};

export default useQr;
