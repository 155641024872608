import { useQuery } from 'react-query';
import { api } from './api.axios';

const getUserList = async () => {
  return await api.get<any | any>('user');
};

const getUserDetail = async (id: string) => {
  return await api.get(`/user/${id}`);
};

export const useUserInfoQuery = (id: string) => {
  const { data, isError, isLoading, isSuccess } = useQuery(
    ['userDetail', id],
    async () => await getUserDetail(id),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  return { data, isLoading, isSuccess, isError };
};

export const useUserListQuery = () => {
  const { data, error, isError, isLoading, isSuccess } = useQuery(
    ['userList'],
    async () => await getUserList(),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  return { data, error, isError, isLoading, isSuccess };
};
