import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseQuery = fetchBaseQuery({
  baseUrl: 'https://dev-otm-api.ijanus.kr',
  prepareHeaders: (headers) => {
    const accesstoken =
      'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MzQsInN1YiI6MzQsImVtYWlsIjoiYmJiYkBtZXRzYWt1dXIuY29tIiwibmFtZSI6Iuq5gCDrr7zsp4AiLCJjb21wYW55SWQiOjEsImxldmVsIjoibWFzdGVyIiwicm9sZUlkIjoxLCJpYXQiOjE3MDg1MDM0MDUsImV4cCI6MTcwODUxNDIwNX0.sMvO008fLtqZmoz6Thkq0farqilL7DRAbv1T1QGH4ZI';

    if (accesstoken) headers.set('Authorization', accesstoken);

    return headers;
  },
});

export const baseQueryWithReauth = async (
  args: any,
  api: any,
  extraOptions: any
) => {
  console.log(args, api, extraOptions);

  let result = await baseQuery(args, api, extraOptions);

  console.log('result', result.error);

  if (result.error?.status === 403) {
    alert('403 error');
  } else if (result.error?.status === 401) {
    alert('401 error');
  }

  return result;
};

export const api = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({}),
});
