import React, { useEffect, useRef } from 'react';
import '../../css/card.css';
import '../../css/web.css';
import { BasicInfo } from '../../components/business/BasicInfo';
import { CircleButton } from '../../components/button/CircleButton';
import { Info } from '../../components/label/Info';
import { CompanyUrl } from '../../components/business/CompanyUrl';
import { useLoaderData } from 'react-router-dom';
import { useUserInfoQuery } from '../../api/reactQuery.api';
import { useUserStoreActions } from '../../store/zustand';
import { Loading } from './Loading';

export const Book = () => {
  const employeeId = useLoaderData() as string;
  const { setUserInfo } = useUserStoreActions();
  const { data, isSuccess } = useUserInfoQuery(employeeId);
  const coverRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        coverRef.current?.classList.add('open');
      }, 1000);
    }
  }, [isSuccess]);

  if (!isSuccess) return <Loading />;
  setUserInfo(data?.data);

  return (
    <div className='card'>
      <div className='inside'>
        <div className='detail'>
          <div>
            <BasicInfo
              classList={{
                name: 'text-center',
                en: 'text-center font-semi',
                role: 'text-center',
              }}
            />
          </div>
          <CompanyUrl classList='px-6 py-4' />
          <div className='info-card'>
            <Info label='Mobile' info='+82. 10 9409 2707' type='tel' />
            <Info label='E-mail' info='aidenlee@metsakuur.com' type='mail' />
            <Info label='Tel' info='+82. 02 6743 0011' type='tel' />
            <Info label='Fax' info='+82. 507 1797 0866' type='tel' />
            <Info
              label='Address'
              info={`(06132) 서울특별시 강남구 테헤란로 141, 13F/14F`}
              classList='whitespace-pre-line text-sm address'
            />
          </div>
          <div className='icon-group flex-between'>
            <div className='flex gap-2'>
              <CircleButton iconClass='qr' clickHandler={() => {}} />
              <CircleButton iconClass='download' clickHandler={() => {}} />
            </div>
            <button className='phone-add' onClick={() => {}}>
              <div className='flex items-center justify-center text-white text-[14px]'>
                연락처 추가
              </div>
              <i className='phone-icon wh-48'></i>
            </button>
          </div>
        </div>

        <div ref={coverRef} className='cover'>
          <div className='flex w-full h-full'>
            <div className='w-full h-full image'></div>
          </div>
        </div>
      </div>
    </div>
  );
};
