import { useUserInfo } from '../../store/zustand';
import { download, addContact } from '../../utils/common';
import { CircleButton } from '../button/CircleButton';
import { BasicInfo } from './BasicInfo';
import { CompanyUrl } from './CompanyUrl';
import { QrSwitchType } from '../../common/type';
import { DetailInfo } from './DetailInfo';

export const BizContent = ({ switchToQrPage }: QrSwitchType) => {
  const userInfo = useUserInfo();
  const { krName, employeeId } = userInfo;
  return (
    <div className='py-10 flex-center brand-color'>
      <div className='flex flex-col gap-6 back-content'>
        <div className='back-logo'></div>
        <BasicInfo
          classList={{
            name: 'font-color',
            en: 'text-base en-name font-color',
            role: 'font-semi font-color',
          }}
        />
        <CompanyUrl classList='link' />
        <DetailInfo />
        <div className='icon-group flex-between'>
          <div className='flex gap-2'>
            <CircleButton iconClass='qr' clickHandler={switchToQrPage} />
            <CircleButton
              iconClass='download'
              clickHandler={() => download(krName, employeeId)}
            />
          </div>
          <button className='phone-add' onClick={() => addContact(userInfo)}>
            <div className='flex items-center justify-center text-white text-[14px]'>
              연락처 추가
            </div>
            <i className='phone-icon wh-48'></i>
          </button>
        </div>
      </div>
    </div>
  );
};
