import { create } from 'zustand';

export interface UserDetailType {
  clevel: string | null;
  created: Date;
  deleted: Date | null;
  email: string;
  employeeId: string;
  enName: string;
  fax: string;
  id: number;
  krName: string;
  phone: string;
  position: string;
  researcherRank: {
    created: Date;
    deleted: Date | null;
    id: number;
    krName: string;
    enName: string;
    updated: Date;
  };
  tel: string;
  updated: Date;
  rank: {
    created: Date;
    deleted: Date | null;
    id: number;
    krName: string;
    enName: string;
    updated: Date;
  };
  organization: {
    created: Date;
    deleted: Date | null;
    id: number;
    name: string;
    updated: Date;
    parent: {
      created: Date;
      deleted: Date | null;
      id: number;
      name: string;
      updated: Date;
    };
  };
}

interface UserStoreStates {
  userInfo: UserDetailType;
}

interface UserStoreActions {
  actions: {
    setUserInfo: (userInfo: any) => void;
  };
}

const initialStates = {
  userInfo: {
    clevel: null,
    created: new Date(),
    deleted: null,
    email: '',
    employeeId: '',
    enName: '',
    fax: '',
    id: 0,
    krName: '',
    phone: '',
    position: '',
    researcherRank: {
      created: new Date(),
      deleted: new Date(),
      id: 0,
      krName: '',
      enName: '',
      updated: new Date(),
    },
    tel: '',
    updated: new Date(),
    rank: {
      created: new Date(),
      deleted: new Date(),
      id: 0,
      krName: '',
      enName: '',
      updated: new Date(),
    },
    organization: {
      created: new Date(),
      deleted: new Date(),
      id: 0,
      name: '',
      updated: new Date(),
      parent: {
        created: new Date(),
        deleted: new Date(),
        id: 0,
        name: '',
        updated: new Date(),
      },
    },
  },
};

const userStore = create<UserStoreStates & UserStoreActions>((set) => ({
  ...initialStates,
  actions: {
    setUserInfo: (userInfo) => set(() => ({ userInfo })),
  },
}));

// 두번째 인자로 () => true 해주면 리렌더링 되지 않는다.
// export const useGetUserInfo = (keys: string[]) => {
//   // component 마다 원하는 값만 가져오기 위해
//   const seletorFunction = useCallback(
//     (state: UserStoreStates & UserStoreActions) => {
//       const { userInfo } = state;
//       return selectItem(keys, userInfo);
//       console.log(test);
//     },
//     []
//   );

//   return userStore(
//     seletorFunction,
//     (prev, current) => JSON.stringify(prev) === JSON.stringify(current)
//   );
// };

export const useUserInfo = () =>
  userStore(
    (state) => state.userInfo,
    (prev, current) => JSON.stringify(prev) === JSON.stringify(current)
  );

export const useUserStoreActions = () => userStore((state) => state.actions);

export default userStore;
