import React from 'react';

interface CompanyUrlProps {
  classList: string;
}

export const CompanyUrl = ({ classList }: CompanyUrlProps) => {
  return (
    <div className={classList}>
      <div className='company'>
        <i className='link-logo wh-48'></i>
        <div className='text-xl company-name flex-center font-semi'>
          (주)메사쿠어컴퍼니
        </div>
      </div>
      <div className='pt-4 flex-between move'>
        <div className='link-icon wh-24'></div>
        <a href='https://www.metsakuur.com' target='_blank'>
          metsakuur.com
        </a>
        <div className='arrow-icon wh-24'></div>
      </div>
    </div>
  );
};
