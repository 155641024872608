import { useUserInfo } from '../../store/zustand';
import { Info } from '../label/Info';

export const DetailInfo = () => {
  const { email, phone } = useUserInfo();
  return (
    <div className='info'>
      <Info label='Mobile' info={phone} type='tel' />
      <Info label='E-mail' info={email} type='mail' />
      <Info label='Tel' info='+82. 02. 6743. 0011' type='tel' />
      <Info label='Fax' info='+82. 507. 1797. 0866' type='tel' />
      <Info
        label='Address'
        info={`(06132) 서울특별시 강남구 테헤란로 141, 13F/14F \n 13F/14F, Teheran-ro Gangnam-gu, Seoul, korea`}
        classList='whitespace-pre-line text-sm address'
      />
    </div>
  );
};
