import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './store';
import './css/main.css';
import {
  QueryClient,
  QueryClientProvider,
  QueryCache,
  MutationCache,
} from 'react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      onError: (error: any) => {
        console.log('index', error);

        switch (error.response?.status) {
          case 400:
            break;

          default:
            break;
        }
      },
    },
  },
  queryCache: new QueryCache({
    onError: (error: any) => {
      // errorHandler(error.response.status);
      console.log('error query chach', error);
    },
    onSuccess: (data, query) => {
      console.log(data, query, 'queryCache success');
    },
  }),
  mutationCache: new MutationCache({
    onError: (error: any) => {
      // errorHandler(error.response.status);
      console.log('error query mutationCache', error);
    },
  }),
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <App />
    </Provider>
  </QueryClientProvider>
);

reportWebVitals();
