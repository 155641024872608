import * as fs from 'file-saver';
import { UserDetailType } from '../store/zustand';
import { BUSINESSURL } from '../constants/env';

export const download = (name: string, id: string) => {
  if (navigator.share) {
    navigator
      .share({
        title: name,
        text: `${name}님의 모바일 명함`,
        url: `${BUSINESSURL}/${id}`,
      })
      .then(() => console.log('공유 성공'))
      .catch((error) => console.log('공유 실패', error));
  }
};

export const addContact = (userInfo: UserDetailType) => {
  const {
    krName,
    email,
    phone,
    enName,
    researcherRank,
    organization,
    rank,
    employeeId,
  } = userInfo;

  // 공통 작업 필요
  const RoleName = () => {
    if (researcherRank) return researcherRank.krName;
    else return rank.krName;
  };

  const HubName = () => {
    if (organization) return `${organization.name}`;
    else return rank.enName;
  };

  let vCardFormat =
    'BEGIN:VCARD\n' +
    'VERSION:3.0\n' +
    `N;CHARSET=UTF-8:${krName};;;\n` +
    `ORG;CHARSET=UTF-8:${HubName()} / ${RoleName()}\n` +
    `TITLE;CHARSET=UTF-8:${enName}\n` +
    `mobile.TEL;type=Mobile:${phone}\n` +
    'mobile.X-ABLabel;CHARSET=UTF-8:Mobile\n' +
    `email.EMAIL;type=E-mail:${email}\n` +
    'email.X-ABLabel;CHARSET=UTF-8:E-mail\n' +
    'tel.TEL;type=Tel:+82. 2. 6743. 0011\n' +
    'tel.X-ABLabel;CHARSET=UTF-8:Tel\n' +
    'fax.TEL;type=Fax:+82. 507. 1797. 0866\n' +
    'fax.X-ABLabel;CHARSET=UTF-8:Fax\n' +
    'address.ADR;CHARSET=UTF-8:;(06132) 서울특별시 강남구 테헤란로 141, 13F/14F;13F/14F, 141, Teheran-ro, Gangnam-gu, Seoul,;Korea;;;\n' +
    'address.X-ABLabel;CHARSET=UTF-8:Address\n' +
    `card.URL:${BUSINESSURL}/${employeeId}\n` +
    'card.X-ABLabel;CHARSET=UTF-8:eBusiness Card\n' +
    'homepage.URL:https://www.metsakuur.com\n' +
    'homepage.X-ABLabel;CHARSET=UTF-8:metsakuur.com\n' +
    'END:VCARD';

  const vCardBlob = new Blob([vCardFormat], {
    type: 'text/vcard',
  });

  fs.saveAs(vCardBlob, 'metsakuur.vcf');
};

export const tel = (info: string) => {
  document.location.href = `tel:${info}`;
};

export const mailto = (info: string) => {
  document.location.href = `mailto:${info}`;
};

export const errorHandler = (error: number) => {
  if (error === 401) {
    alert('401 error');
  }
};

export const selectItem = (keys: string[], info: any) => {
  return keys.reduce<Record<string, string>>(
    (acc, value) => ({ ...acc, [value]: info[value] }),
    {}
  );
};
