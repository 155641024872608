interface CircleButtonProps {
  iconClass: string;
  clickHandler: () => void;
}

export const CircleButton = ({
  iconClass,
  clickHandler,
}: CircleButtonProps) => {
  return (
    <button
      className='w-12 h-12 flex-center group'
      onClick={() => clickHandler()}
    >
      <i className={`wh-24 ${iconClass}`}></i>
    </button>
  );
};
