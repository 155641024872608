import { BasicInfo } from './BasicInfo';
import { QrSwitchType } from '../../common/type';
import { Qr } from './Qr';

export const QrTemplate = ({ switchToQrPage }: QrSwitchType) => {
  return (
    <div className='flex flex-col gap-4 px-4 pt-4'>
      <button className='qr-close wh-36' onClick={switchToQrPage}></button>
      <div className='flex-col flex-center'>
        <div className='qr-logo-circle wh-120 flex-center'>
          <i className='qr-logo'></i>
        </div>
        <BasicInfo
          classList={{
            name: 'text-center',
            en: 'text-center font-semi',
            role: 'text-center',
          }}
        />
      </div>
      <div className='flex-center'>
        <Qr />
      </div>
    </div>
  );
};
