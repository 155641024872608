import React from 'react';
import { mailto, tel } from '../../utils/common';

interface InfoProps {
  label: string;
  info: string;
  type?: string;
  classList?: string;
}

export const Info = ({ label, info, type, classList }: InfoProps) => {
  const clickHandler = () => {
    if (type === 'tel') {
      tel(info);
    } else if (type === 'mail') {
      mailto(info);
    } else return;
  };

  return (
    <div>
      <label>{label}</label>
      <div onClick={clickHandler} className={classList}>
        {info}
      </div>
    </div>
  );
};
