import React from 'react';

export const Loading = () => {
  return (
    <div className='flex items-center justify-center w-full h-full bg-green'>
      <div className='font-serif text-orange text-8xl loading'>
        <span>M</span>
        <span>e</span>
        <span>t</span>
        <span>s</span>
        <span>a</span>
        <span>k</span>
        <span>r</span>
        <span>e</span>
        <span>w</span>
      </div>
    </div>
  );
};
