import { configureStore } from '@reduxjs/toolkit';
import userReducer from '../redux/user';
import { pokemonApi } from '../api/api';
import { setupListeners } from '@reduxjs/toolkit/query';
import { userApi } from '../api/user.api';

export const store = configureStore({
  reducer: {
    user: userReducer,
    // [pokemonApi.reducerPath]: pokemonApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(userApi.middleware),
  // middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware().concat(pokemonApi.middleware, userApi.middleware),
});

setupListeners(store.dispatch);
