import { api } from './base.api';

export const userApi = api.injectEndpoints({
  endpoints: (build) => ({
    userList: build.query({
      query: () => 'user',
      // providesTags???? 모야 이거
      providesTags: (result = [], error, arg) => [
        'Post',
        ...result.map(({ id }: { id: any }) => ({ type: 'Post', id })),
      ],
      // transformResponse: (response: { data: {} }, meta, arg) => {
      //   console.log('responser', response);

      //   return response;
      // },
    }),
    userDetail: build.query({
      query: (userId) => `user/${userId}`,
      // providesTags: (result, error, arg) => [{ type: 'Post', id: arg }],
      transformResponse: (response: { data: any }) => {
        // console.log('setData', store, setData(response));
        // store.dispatch(setData(response));

        // const dispatch = useDispatch();
        // dispatch(setData(response));
        console.log('transform', response);
        return response;
      },
    }),
    postUser: build.mutation({
      query: () => 'user',
      // invalidatesTags: [{ type: 'post', id: 'List' }],
    }),
  }),
  overrideExisting: false,
});

export const { useUserListQuery, useUserDetailQuery, usePostUserMutation } =
  userApi;
