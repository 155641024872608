// import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
// import { RouteTest } from '../pages/RouteTest';
// import { Home } from '../components/home/Home';
// import Layout from '../components/layout/Layout';
// import { Calendar } from '../components/calendar/Calendar';
// import { Loading } from '../components/loading/Loading';
import { Book } from '../pages/business-card/Book';
import { Loading as BusinessLoading } from '../pages/business-card/Loading';
import { Card } from '../pages/business-card/Card';
import { UserList } from '../pages/UserList';
import { BizCard } from '../pages/business-card/BizCard';

const loader = (id: string) => {
  return id;
};

export const router = createBrowserRouter([
  {
    // element: <Layout />,
    children: [
      {
        path: '/*',
        element: <BusinessLoading />,
      },
      // { path: '/calendar', element: <Calendar /> },
      // { path: '/route', element: <RouteTest /> },
    ],
  },
  // {
  //   path: '/aaaa',
  //   element: <RouteTest />,
  //   errorElement: <Loading />,
  // },
  {
    path: 'metsa-kard',
    children: [
      {
        path: '/metsa-kard',
        element: <BusinessLoading />,
      },

      // {
      //   path: '/metsa-kard/:employeeId',
      //   loader: async ({ params }) => {
      //     const { employeeId } = params;
      //     return employeeId && loader(employeeId);
      //   },
      //   element: <BizCard />,
      // },
      // rtk-query 확인용
      // {
      //   path: '/metsa-kard',
      //   element: <Web />,
      // },
      {
        path: '/metsa-kard/card/:employeeId',
        loader: async ({ params }) => {
          const { employeeId } = params;
          return employeeId && loader(employeeId);
        },
        element: <Card />,
      },
      {
        path: '/metsa-kard/book/:employeeId',
        loader: async ({ params }) => {
          const { employeeId } = params;
          return employeeId && loader(employeeId);
        },
        element: <Book />,
      },
    ],
  },
  {
    path: '/user',
    children: [
      {
        path: '/user',
        element: <UserList />,
      },
      {
        path: '/user/:employeeId',
        loader: async ({ params }) => {
          const { employeeId } = params;
          return employeeId && loader(employeeId);
        },
        element: <BizCard />,
      },
    ],
  },
]);
