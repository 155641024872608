import { useEffect, useRef } from 'react';
import { useLocation, useLoaderData } from 'react-router-dom';
import { useUserInfoQuery } from '../../api/reactQuery.api';
import { useUserStoreActions } from '../../store/zustand';
import { Loading } from './Loading';
import { BizTemplate } from '../../components/business/BizTemplate';

export const BizCard = () => {
  const employeeId = useLoaderData() as string;
  const query = new URLSearchParams(useLocation().search);
  const root = document.getElementById('root');
  const webRef = useRef<HTMLDivElement>(null);
  const { setUserInfo } = useUserStoreActions();
  const { data, isSuccess } = useUserInfoQuery(employeeId);

  useEffect(() => {
    const theme = query.get('theme');
    if (root && theme === 'yellow') {
      root.style.setProperty('--brand-color', '#F5CD21');
      root.style.setProperty('--font-color', '#231f20');
    }
  }, []);

  if (!isSuccess) return <Loading />;
  setUserInfo(data?.data);

  return (
    <div ref={webRef} className='w-full h-full web'>
      <BizTemplate />
    </div>
  );
};
