import React, { useEffect, useRef } from 'react';
import '../../css/step3.css';
import { BasicInfo } from '../../components/business/BasicInfo';
import { Info } from '../../components/label/Info';
import { CircleButton } from '../../components/button/CircleButton';
import { download } from '../../utils/common';
import { useUserStoreActions } from '../../store/zustand';
import { useLoaderData } from 'react-router-dom';
import { useUserInfoQuery } from '../../api/reactQuery.api';
import { Loading } from './Loading';

export const Card = () => {
  const employeeId = useLoaderData() as string;
  const { setUserInfo } = useUserStoreActions();
  const { data, isSuccess } = useUserInfoQuery(employeeId);

  if (!isSuccess) return <Loading />;
  setUserInfo(data?.data);

  function switchToQrPage(): void {
    throw new Error('Function not implemented.');
  }

  return (
    <div className='flex parent'>
      <div className='bar profile-social-links'>
        <ul className='profile-social-links'>
          <li>
            <li>
              <CircleButton iconClass='qr' clickHandler={switchToQrPage} />
            </li>
          </li>
          <li>
            <CircleButton iconClass='qr' clickHandler={switchToQrPage} />
          </li>
          <li>
            <CircleButton
              iconClass='download'
              clickHandler={() => download('name', '')}
            />
          </li>
          <li>
            <li>
              <CircleButton iconClass='qr' clickHandler={switchToQrPage} />
            </li>
          </li>
        </ul>
      </div>
      <div className='card-content'>
        <div className='shown text'>
          <BasicInfo
            classList={{
              name: 'text-center',
              en: 'text-center font-semi',
              role: 'text-center',
            }}
          />
          <div className='flex flex-col gap-3 py-12 pr-12 test pl-7'>
            <Info label='Mobile' info='+82. 10 7685 8901' type='tel' />
            <Info label='E-mail' info='aidlee@metsakuur.com' type='mail' />
            <Info label='Tel' info='+82. 02 6743 0011' type='tel' />
            <Info label='Fax' info='+82. 507 1797 0866' type='tel' />
            <Info
              label='Address'
              info={`(06132) 서울특별시 강남구 테헤란로 141, 13F/14F \n 13F/14F, Teheran-ro Gangnam-gu, Seoul, korea`}
              classList='whitespace-pre-line text-sm address'
            />
          </div>
          <div className='metsa-logo'></div>
        </div>
      </div>
    </div>
  );
};
