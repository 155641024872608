import { useUserListQuery } from '../api/reactQuery.api';
import { UserListType } from '../types/user';
import { Link } from 'react-router-dom';

export const UserList = () => {
  const { data, error, isSuccess } = useUserListQuery();

  if (!isSuccess) return <>{error}</>;

  return (
    <div className='p-1'>
      <p className='p-2 text-2xl text-center'>메사쿠어 임직원 리스트</p>
      <ul className='grid grid-cols-3 gap-1'>
        {data?.data.map((user: UserListType) => (
          <li
            key={user.employeeId}
            className='flex items-center justify-center h-12 border border-500'
          >
            <Link to={`/user/${user.employeeId}`}>
              {user.employeeId}: {user.krName}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};
